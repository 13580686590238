import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import scheduleApolloClient from '@/lib/appsync/schedule';
import workflowApolloClient from '@/lib/appsync/workflow';

import postNewUserScheduleRule from '@/graphql/userSettings/mutations/postNewUserScheduleRule.graphql';
import deleteScheduledJob from '@/graphql/schedule/mutations/deleteScheduledJob.graphql';
import deleteGroup from '@/graphql/schedule/mutations/deleteGroup.graphql';
import getGroups from '@/graphql/schedule/queries/getGroups.graphql';
import getGroup from '@/graphql/schedule/queries/getGroup.graphql';
import getWellTags from '@/graphql/schedule/queries/getWellTags.graphql';
import getOperatorAssets from '@/graphql/schedule/queries/getOperatorAssets.graphql'


import getShiftTemplates from '@/graphql/schedule/queries/getShiftTemplates.graphql'
import deleteRow from '@/graphql/schedule/mutations/deleteRow.graphql';

import getPersonnelSchedule from '@/graphql/schedule/queries/getPersonnelSchedule.graphql';
import getPersonnelWorkspaceSchedules from '@/graphql/schedule/queries/getPersonnelWorkspaceSchedules.graphql';

import getPersonnelShiftsGantt from '@/graphql/schedule/queries/getPersonnelShiftsGantt.graphql';


import updateItemStatus from '@/graphql/schedule/mutations/updateItemStatus.graphql';

import postCustomShift from'@/graphql/schedule/mutations/postCustomShift.graphql'
import updateShiftSchedule from '@/graphql/schedule/mutations/updateShiftSchedule.graphql';
import updateShiftScheduleDetails from '@/graphql/schedule/mutations/updateShiftScheduleDetails.graphql';
import deleteShiftSchedule from '@/graphql/schedule/mutations/deleteShiftSchedule.graphql';
import deleteCustomShift from '@/graphql/schedule/mutations/deleteCustomShift.graphql';
import deleteShiftTemplate from '@/graphql/schedule/mutations/deleteShiftTemplate.graphql';

import postCustomShiftBatch from '@/graphql/schedule/mutations/postCustomShiftBatch.graphql';
import deleteCustomShiftBatch from '@/graphql/schedule/mutations/deleteCustomShiftBatch.graphql';
import putCustomShiftBatch from '@/graphql/schedule/mutations/putCustomShiftBatch.graphql';

import postNewScheduledJob from '@/graphql/userSettings/mutations/postNewScheduledJob.graphql';
import postNewShiftTemplate from '@/graphql/schedule/mutations/postNewShiftTemplate.graphql';
import putScheduledJob from '@/graphql/schedule/mutations/putScheduledJob.graphql';
import postGanttMutation from '@/graphql/schedule/mutations/postGantt.graphql';
import postGroupMutation from '@/graphql/schedule/mutations/postGroup.graphql';

import updateShiftScheduleTemplateGraphql from '@/graphql/schedule/mutations/updateShiftScheduleTemplate.graphql';


import postNewShiftSchedule from '@/graphql/schedule/mutations/postNewShiftSchedule.graphql';
import postNewShiftRowVal from '@/graphql/schedule/mutations/postNewShiftRow.graphql';



import deleteWorkspaceGraphql from '@/graphql/schedule/mutations/deleteWorkspace.graphql';
import deleteTableGraphql from '@/graphql/schedule/mutations/deleteTable.graphql';
import deleteGroupGraphql from '@/graphql/schedule/mutations/deleteGroup.graphql';
import deleteItemGraphql from '@/graphql/schedule/mutations/deleteItem.graphql';



import updateTable from '@/graphql/schedule/mutations/updateTable.graphql';
import updateGroup from '@/graphql/schedule/mutations/updateGroup.graphql';
import addTagsToWell from '@/graphql/schedule/mutations/addTagsToWell.graphql';

import updateScheduleItemGraphql from '@/graphql/schedule/mutations/updateScheduleItem.graphql';


import postClusterMutation from '@/graphql/schedule/mutations/postCluster.graphql';

import putTasksParams from '@/graphql/schedule/mutations/putTasksParams.graphql';

import postNewGroup from '@/graphql/schedule/mutations/postNewGroup.graphql';
import postNewTable from '@/graphql/schedule/mutations/postNewTable.graphql';
import postNewItem from '@/graphql/schedule/mutations/postNewItem.graphql';

import postNewRule from '@/graphql/schedule/mutations/postNewRule.graphql';


import updateScheduledJobDateRanges from '@/graphql/schedule/mutations/updateScheduledJobDateRanges.graphql';


import completePrePostTasqJob from '@/graphql/schedule/mutations/completePrePostTasqJob.graphql';
import deleteRig from '@/graphql/schedule/mutations/deleteRig.graphql';
import deletePrePostTasq from '@/graphql/schedule/mutations/deletePrePostTasq.graphql';
import deleteRule from '@/graphql/schedule/mutations/deleteRule.graphql';
import deleteTableRule from '@/graphql/schedule/mutations/deleteTableRule.graphql';




import postNewPrePostTasqTemplate from '@/graphql/userSettings/mutations/postNewPrePostTasqTemplate.graphql';
import postNewRig from '@/graphql/userSettings/mutations/postNewRig.graphql';
import putScoot from '@/graphql/schedule/mutations/putScoot.graphql';



import getScheduleRulesResponse from '@/graphql/userSettings/queries/getScheduleRulesResponse.graphql';
import getScheduledJobsResponse from '@/graphql/userSettings/queries/getScheduledJobsResponse.graphql';
import getScheduleJobsV2 from '@/graphql/schedule/queries/getScheduleJobsV2.graphql';
import getComponentsResponse from '@/graphql/schedule/queries/getComponentsResponse.graphql';
import getVendorsResponse from '@/graphql/schedule/queries/getVendorsResponse.graphql';
import getEnabledRoutes from '@/graphql/schedule/queries/getEnabledRoutes.graphql';

import listAreasQuery from '@/graphql/schedule/queries/listAreas.graphql';


import getGanttChartIDs from '@/graphql/schedule/queries/getGanttChartIDsResponse.graphql';

import getGanttResponse from '@/graphql/schedule/queries/getGanttResponse.graphql';

import getScheduleTemplates from '@/graphql/schedule/queries/getScheduleTemplates.graphql';
import getColumnMapping from '@/graphql/schedule/queries/getColumnMapping.graphql';

import getPrePostTasqTemplateResponse from '@/graphql/userSettings/queries/getPrePostTasqTemplateResponse.graphql';
import PrePostTasqTemplate from '@/interfaces/schedule/PrePostTasqTemplate';
import { mapPrePostTasqTemplate } from '@/utils/scheduleJobs';
import { controllers } from 'chart.js';
import accountModule from '@/store/modules/accountModule';
import userPreferenceDB from '@/lib/userPreference';

import { DateTime } from 'luxon';
@Module({
  dynamic: true,
  namespaced: true,
  name: 'scheduleModule',
  store,
})
class ScheduleModule extends VuexModule {

  scheduleRules: any[] = []
  scheduleJobs: any[] = []
  scheduleComponents: any[] = []

  activeTableGroups : any = []

  availableTags: any = [];

  activePage: any = null;

  enabledRoutes: any[] = []

  ganttChartIDs: any[] = []

  areas: any[] = []

  preTasqTemplateList: PrePostTasqTemplate[] = []
  postTasqTemplateList: PrePostTasqTemplate[] = []

  scheduledRowComponents: any[] = []

  scheduleTemplates: any[] = []

  activeGroup: any = null;

  columnMapping: any[] = []

  recentlyPostedTableID: any = null

  activeShiftTemplateEdit: any = null

  activeComponent: any = null
  activeComponentResponseColumns: any = null
  accountDetails: any = null

  expandedFile: any = null

  confirmDeleteFile: any = null

  selectedTabID: any = null


  activeComponentTabs: any = []

  editGroupDetails: any = null
  addShiftRowDetails: any = null

  // Personnel Schedule Variables
  activePersonnelWorkspace: any = null
  activePersonnelSchedule: any = null
  personnelSchedules: any = []
  personnelScheduleAssets: any = []
  personnelScheduleShiftTemplates: any = []

  isSavingAddNewSchedule: any = false

  recentlyPostedNewScheduleID: any = false

  totalScheduledRowComponents: any = []

  operatorAssetsOutput: any = []

  totalComponents: any = []

  @Mutation
  setOperatorAssetsOutput({data}): void {
	this.operatorAssetsOutput = data
  }

  @Mutation
  resetFilterScheduledRowComponents(): void {
	this.totalScheduledRowComponents = []
  }

  @Mutation
  resetTotalComponents(): void {
	this.totalComponents = []
  }

  @Mutation
  setTotalComponents(data): void {
	function compare( a, b ) {
		if ( (new Date(a.start_date)) < (new Date(b.start_date)) ){
			return -1;
		}
		if ( (new Date(a.start_date)) > (new Date(b.start_date)) ){
			return 1;
		}
		return 0;
	}
	this.totalComponents = data.sort( compare )

  }


  @Mutation
  updateComponent(data): void {
	this.totalComponents[data.index].StartTime = data.start_datetime
	this.totalComponents[data.index].EndTime = data.end_datetime
	this.totalComponents[data.index].Asset = data.asset
	this.totalComponents[data.index].AssetType = data.level
	this.totalComponents[data.index].changed = true
  }



  @Mutation
  addFilterScheduledRowComponent(data, index=null): void {
	  if (index != null) {
		  // @ts-ignore
		this.totalScheduledRowComponents[index] = data
	  } else {
		this.totalScheduledRowComponents.push(data)
	  }
  }

  @Mutation
  addFilterScheduledRow({data, filter_index}): void {
		this.totalScheduledRowComponents[filter_index].rows.push(data)
		// this.totalScheduledRowComponents[filter_index].rows[row_index].components.push(data)

  }

  @Mutation
  setFilterScheduledRowComponents({data}): void {
		this.totalScheduledRowComponents = data
		// this.totalScheduledRowComponents[filter_index].rows[row_index].components.push(data)

  }


  @Mutation
  setActiveShiftTemplateEdit({template}): void {
	  if (template == null) {
		this.activeShiftTemplateEdit = template
	  }
	  this.activeShiftTemplateEdit = template;

  }


  @Mutation
  addFilterScheduledComponent({data}): void {
	  this.totalComponents.push(data)
		// this.totalScheduledRowComponents[0].rows[0].components.push(data)
		// totalScheduledRowComponents
		// herrrrrrr
  }

  @Mutation
  deleteScheduledComponent({component_index, hidden, changed}): void {
	this.totalComponents[component_index].hidden = hidden
	this.totalComponents[component_index].changed = changed
	this.totalComponents[component_index].should_delete = true
  }

  @Mutation
  setFilterScheduledComponent({filter_schedule_component_index, row_index, component_index, start_date, end_date, well}): void {
	this.totalScheduledRowComponents[filter_schedule_component_index].rows[row_index].components[component_index].start_date = start_date
	this.totalScheduledRowComponents[filter_schedule_component_index].rows[row_index].components[component_index].end_date = end_date
	this.totalScheduledRowComponents[filter_schedule_component_index].rows[row_index].components[component_index].well = well
	this.totalScheduledRowComponents[filter_schedule_component_index].rows[row_index].components[component_index].changed = true
  }

  @Mutation
  bulkSetFilterScheduledComponent({data}): void {
	  for (var x = 0; x < data.length; x++) {
		this.totalScheduledRowComponents[data[x].filter_schedule_component_index].rows[data[x].row_index].components[data[x].component_index].start_date = data[x].start_date
		this.totalScheduledRowComponents[data[x].filter_schedule_component_index].rows[data[x].row_index].components[data[x].component_index].end_date = data[x].end_date
		this.totalScheduledRowComponents[data[x].filter_schedule_component_index].rows[data[x].row_index].components[data[x].component_index].well = data[x].well
		this.totalScheduledRowComponents[data[x].filter_schedule_component_index].rows[data[x].row_index].components[data[x].component_index].title_row = data[x].selection_name
	  }

  }

  @Mutation
  setFilterScheduledComponentDetails({component_index, start_date, end_date, well, username, asset_type, shift_template_id, row_id}): void {
	this.totalComponents[component_index].StartTime = start_date
	this.totalComponents[component_index].EndTime = end_date
	this.totalComponents[component_index].Asset = well
	this.totalComponents[component_index].AssetType = asset_type
	this.totalComponents[component_index].Username = username
	this.totalComponents[component_index].TemplateID = shift_template_id
	this.totalComponents[component_index].changed = true
	if (this.totalComponents[component_index].is_from_edit != null) {
		this.totalComponents[component_index].is_from_edit.date = start_date
		this.totalComponents[component_index].is_from_edit.username = username
		this.totalComponents[component_index].is_from_edit.assetType = asset_type
		this.totalComponents[component_index].is_from_edit.asset = well
		this.totalComponents[component_index].is_from_edit.shiftTemplateID = shift_template_id
		this.totalComponents[component_index].is_from_edit.RowID = row_id
	}
  }

  @Mutation
  updateFilterScheduledRowComponentName(name, index): void {
	this.totalScheduledRowComponents[index].title_row.selection_name = name
}

  @Mutation
  resetPersonnelScheduleDetails(): void {
	this.activePersonnelWorkspace = null
	this.activePersonnelSchedule = null
	this.personnelSchedules = []
  }

  @Mutation
  resetPersonnelScheduleShifts(): void {
	this.personnelScheduleShiftTemplates = []
  }

  @Mutation
  resetPersonnelScheduleAssets(): void {
	this.personnelScheduleAssets = []
  }

  @Mutation
  resetRoutes(): void {
    this.enabledRoutes = [];
  }


  @Mutation
  resetScheduleRules(): void {
    this.scheduleRules = [];
  }

  @Mutation
  resetScheduleJobs(): void {
    this.scheduleJobs = [];
  }

  @Mutation
  resetScheduleComponents(): void {
    this.scheduleComponents = [];
  }


  @Mutation
  setRecentlyPostedNewScheduleID(id): void {
    this.recentlyPostedNewScheduleID = id;
  }

  @Mutation
  setPersonnelScheduleDetails(workspaceResults): void {
	if (Object.keys(workspaceResults).length > 0) {

		this.activePersonnelWorkspace = workspaceResults[Object.keys(workspaceResults)[0]]
		this.activePersonnelSchedule = null
		this.personnelSchedules = workspaceResults[this.activePersonnelWorkspace.ID]["Schedules"]
		if (workspaceResults[this.activePersonnelWorkspace.ID]["Schedules"].length > 0) {
			this.activePersonnelSchedule = workspaceResults[this.activePersonnelWorkspace.ID]["Schedules"][0]
		}
	}
  }



  @Mutation
  addPersonnelScheduleAssets(scheduleAssets): void {
	this.personnelScheduleAssets = scheduleAssets
  }

  @Mutation
  addPersonnelScheduleShiftTemplates(item): void {
	for (let index=0; index<this.personnelScheduleShiftTemplates.length; index++) {
		if (this.personnelScheduleShiftTemplates[index].id == item.id) {
			return
		}
	}
	this.personnelScheduleShiftTemplates.push(item)
  }

  @Mutation
  setIsSavingAddNewSchedule(val): void {
    this.isSavingAddNewSchedule = val;
  }

  @Mutation
  setAreas(data): void {
    this.areas = data.sort();
  }

  @Mutation
  setActiveSchedule({id}): void {
    this.activePersonnelSchedule = this.personnelSchedules.find((i) => i.ID === id);
  }


  @Mutation
  setActiveGroup(data): void {
	this.activeGroup = data;
  }


  @Mutation
  setEditGroupDetails(data): void {
	this.editGroupDetails = data
  }


  @Mutation
  setAddShiftRowDetails(data): void {
	this.addShiftRowDetails = data
  }


  @Mutation
  setSelectedTabID(data): void {
	this.selectedTabID = data
  }



  @Mutation
  setConfirmDeleteFile(file): void {
	this.confirmDeleteFile = file
  }


  @Mutation
  setExpandedFile(file): void {
	this.expandedFile = file
  }



  @Mutation
  setRecentlyPostedTableID(data): void {
	this.recentlyPostedTableID = data;
  }

  @Mutation
  setActiveTableGroups(data): void {
	if(data){
		// console.log(data)
		let updatedData = Object.keys(data).map(key => {
			if(data[key].Metadata && data[key].Metadata.tags){
			const tags = data[key].Metadata.tags
			const wellCount = data[key].Metadata.wellCount
			data[key].tags = tags
			data[key].wellCount = wellCount
			}
				return data[key]
		})


		this.activeTableGroups = updatedData
	}
  }



  @Mutation
  setColumnMapping(data): void {
	this.columnMapping = data;
  }




  @Mutation
  setScheduleTemplates(data): void {
	this.scheduleTemplates = data;
  }

  @Mutation
  setActivePage(page): void {
	this.activePage = page;
  }



  @Mutation
  setGanttJobs(get_chart_ids): void {
	this.ganttChartIDs = get_chart_ids;
  }



  @Mutation
  setGanttJob(get_chart_id): void {
	this.ganttChartIDs.push(JSON.parse(get_chart_id));
  }


  @Mutation
  addRoute(route): void {
    this.enabledRoutes.push(route);
  }

  @Mutation
  setRoute(routes): void {
    this.enabledRoutes = routes;
  }

  @Mutation
  setScheduleJobs(schedule_jobs): void {
    this.scheduleJobs = schedule_jobs;
  }

  @Mutation
  setScheduleRules(schedule_rules): void {
    this.scheduleRules = schedule_rules;
  }

  @Mutation
  setScheduleComponents(schedule_components): void {
    this.scheduleComponents = schedule_components;
  }



  @Mutation
  addScheduleRule(rule): void {
    this.scheduleRules.push(rule);
  }

  @Mutation
  addPreTasqTemplate(pre_tasq): void {
    this.preTasqTemplateList.push(pre_tasq);
  }

  @Mutation
  addPostTasqTemplate(post_tasq): void {
    this.postTasqTemplateList.push(post_tasq);
  }

  @Mutation
  setAllTags(tags): void {
    this.availableTags= tags.map(t => {
        return JSON.parse(t)
      });
  }

  @Mutation
  resetPrePostTasqTemplate(): void {
    this.postTasqTemplateList = [];
	this.preTasqTemplateList = [];
  }


  @Mutation
  addScheduledJob(data): void {
    this.scheduleJobs = data;
  }


  @Mutation
  setActiveComponent(component): void {
    this.activeComponent = component;
  }

  @Mutation
  setAccountDetails(details): void {
    this.accountDetails = details;
  }

  @Mutation
  setActiveComponentResponseColumns(columns): void {
    this.activeComponentResponseColumns = columns;
  }

  @Mutation
  updateActiveColumnsChecklist(data): void {

	  this.activeComponentResponseColumns[data.single_x].Response[data.single_index].checked = !this.activeComponentResponseColumns[data.single_x].Response[data.single_index].checked;
	}


	@Mutation
	updateColumnByIndex({column_index, column}): void {
		this.activeComponentResponseColumns[column_index] = column
		// this.activeComponentResponseColumns[data.single_x].Response[data.single_index].checked = !this.activeComponentResponseColumns[data.single_x].Response[data.single_index].checked;
	}





	@Mutation
	setSelectedComponentTabs() {
		var tabs = [{
			ColumnType: "DETAILS",
			ColumnName: "Details",
			EditableColumnIDs: [],
			TabID: 1
		}]
		if (this.activeComponent == null) {
			this.activeComponentTabs = tabs
		}
		// @ts-ignore
		for (var x = 0; x < this.activeComponentResponseColumns.length; x++) {
			// @ts-ignore
			if (this.activeComponentResponseColumns[x].ColumnType == "FILE" ||
				// @ts-ignore
				this.activeComponentResponseColumns[x].ColumnType == "PROCEDURE") {
				tabs.push({
					// @ts-ignore
					ColumnType: this.activeComponentResponseColumns[x].ColumnType,
					// @ts-ignore
					ColumnName: this.activeComponentResponseColumns[x].CustomName,
					// @ts-ignore
					EditableColumnIDs: [this.activeComponentResponseColumns[x].CustomID],
					TabID: this.activeComponentResponseColumns[x].CustomID
				})
			} else {
				// @ts-ignore
				tabs[0].EditableColumnIDs.push(this.activeComponentResponseColumns[x].CustomID)
			}
		}

		this.activeComponentTabs = tabs
	}



	@Action
	async deleteCustomShift({
	ShiftID,
	}) {
		try {
			const {
				data: {
					delete_custom_shift
				},
			} = await scheduleApolloClient.mutate({
				mutation: deleteCustomShift,
				variables: {
					input: {
						ID: ShiftID
					},
				},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



	@Action
	async deleteShiftTemplate({
	id,
	}) {
		try {
			const {
				data: {
					delete_shift_template
				},
			} = await scheduleApolloClient.mutate({
				mutation: deleteShiftTemplate,
				variables: {
					input: {
						ID: id
					},
				},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



	@Action
	async deleteShiftSchedule({
	id,
	}) {
		try {
			const {
				data: {
					delete_shift_schedule
				},
			} = await scheduleApolloClient.mutate({
				mutation: deleteShiftSchedule,
				variables: {
					input: {
						ID: id
					},
				},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



	@Action
	async updateShiftScheduleDetails({
	username,
	startTime,
	endTime,
	assetType,
	asset,
	// color,
	ShiftID,
	TemplateID
	}) {
		try {
			const {
			data: {
				update_shift_schedule_details
			},
			} = await scheduleApolloClient.mutate({
			mutation: updateShiftScheduleDetails,
			variables: {
				input: {
					ShiftID: ShiftID,
					StartTime: startTime,
					EndTime: endTime,
					AssetType: assetType,
					Asset: asset,
					// Color: color,
					Username: username,
					TemplateID: TemplateID
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



	@Action
	async updateShiftSchedDates({
	start_time,
	end_time,
	shift_id,
	asset=null
	}) {
		try {
			const {
			data: {
				update_shift_schedule_details
			},
			} = await scheduleApolloClient.mutate({
			mutation: updateShiftScheduleDetails,
			variables: {
				input: {
					ShiftID: shift_id,
					StartTime: start_time,
					EndTime: end_time
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}




	@Action
	async postNewShiftRow({
	workspace_id,
	schedule_id,
	asset_type,
	asset
	}) {
		try {
			const {
			data: {
				post_new_shift_row
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewShiftRowVal,
			variables: {
				input: {
				  Username: accountModule.user.email.toLowerCase(),
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  WorkspaceID: workspace_id,
				  ScheduleID: schedule_id,
				  AssetType: asset_type,
				  Asset: asset
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}






	@Action
	async postNewPersonnelSchedule({
	workspace_id,
	role,
	}) {
		try {
			const {
			data: {
				post_new_shift_schedule
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewShiftSchedule,
			variables: {
				input: {
				  Username: accountModule.user.email.toLowerCase(),
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  Role: role,
				  WorkspaceID: workspace_id,
				},
			},
			});
			this.setRecentlyPostedNewScheduleID(post_new_shift_schedule.ID)
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}







	@Action
	async updateItemStatus({
		item_id,
		item_status,
		payload
	}) {
		try {
			if(payload){
				payload = JSON.stringify(payload)
			}else {
				payload = {}
			}
			const {
			data: {
				update_item_status: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: updateItemStatus,
			variables: {
				input: {
				  Status: {
					  Status: item_status,
					  Payload: payload
				  },
				  ItemID: item_id
				},
			},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}

	}











	@Action
	async deleteTableRule({
	rule_id
	}) {

		try {
			const {
			data: {
			  delete_table_rule: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: deleteTableRule,
			variables: {
				input: {
					RuleID: rule_id
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}






  @Action
  async deleteItem({
  item_id,
  }) {

	  try {
		  const {
		  data: {
			delete_item: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteItemGraphql,
		  variables: {
			  input: {
				  ItemID: item_id,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }






  @Action
  async deleteGroup({
  group_id,
  }) {

	  try {
		  const data  = await scheduleApolloClient.mutate({
		  mutation: deleteGroupGraphql,
		  variables: {
			  input: {
				  GroupID: group_id,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }






  @Action
  async deleteTable({
  table_id,
  }) {

	  try {
		  const {
		  data: {
			delete_table: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteTableGraphql,
		  variables: {
			  input: {
				  TableID: table_id,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async deleteWorkspace({
  workspace_id,
  }) {

	  try {
		  const {
		  data: {
			delete_workspace: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteWorkspaceGraphql,
		  variables: {
			  input: {
				  WorkspaceID: workspace_id,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }






  @Action
  async updateScheduleItem({
  item_id,
  group_id = null,
  columns
  }) {

		var input = {
			Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			ItemID: item_id,
			Columns: columns,
		}
		if (group_id != null) {
			input["GroupID"] = group_id
		}
	  try {
		  const {
		  data: {
			update_scheduled_item: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateScheduleItemGraphql,
		  variables: {
			  input: input,
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  @Action
  async updateTableName({
  table_id,
  table_name
  }) {

	  try {
		  const {
		  data: {
			update_table: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateTable,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  TableID: table_id,
				  TableTitle: table_name
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }








  @Action
  async updateGroupColumns({
  group_id,
  columns
  }) {
	  try {
		  const {
		  data: {
			update_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateGroup,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  GroupID: group_id,
				  Columns: columns
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }











  @Action
  async updateGroupName({
  group_id,
  group_name
  }) {
	  try {
		  const {
		  data: {
			update_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateGroup,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  GroupID: group_id,
				  GroupName: group_name
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async addTagsToWell({
  node_id,
  tags
  }) {
	  try {
		  const data  = await scheduleApolloClient.mutate({
		  mutation: addTagsToWell,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  NodeID: node_id,
				  Tags: tags
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async updateGroupDetails({
  group_id,
  payload,
  columns,
  groupName,
  metadata
  }) {
	  try {
		  const {
		  data: {
			update_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: updateGroup,
		  variables: {
			  input: {
				  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				  GroupID: group_id,
				  Payload: payload,
				  Columns: columns,
				  Metadata: metadata,
				  GroupName: groupName,
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }





  @Action
  async postNewRule({
  workspace_id,
  table_id,
  rule_json,
  }) {

	  try {
		  const {
		  data: {
			post_new_rule: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postNewRule,
		  variables: {
			  input: {
				Username: accountModule.user.email.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				TableID: table_id,
				WorkspaceID: workspace_id,
				RuleJson: rule_json,
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }










  @Action
  async postNewGroupItem({
  username,
  workspace_id,
  table_id,
  group_id,
  columns,
  prediction_id = null
  }) {
	let usage = "SCHEDULE";
	if(this.activePage === 'Groups') {
	  usage = "ASSETS";
    }

	var input = {
		Username: username.toLowerCase(),
		Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		TableID: table_id,
		WorkspaceID: workspace_id,
		Columns: columns,
		GroupID: group_id,
		Usage: usage
	  }
	  if (prediction_id != null) {
		input["PredictionID"] = prediction_id
	  }
	  try {
		  const {
		  data: {
			post_new_item: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postNewItem,
		  variables: {
			  input: input,
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async getTableGroups({
  table_id,
  excludePayload = false,
  }) {
	  try {
		  const {
		  data: {
			  // @ts-ignore
			get_groups
		  },
		  // eslint-disable-next-line no-tabs
		  } = await scheduleApolloClient.query({
		  query: getGroups,
		  variables: {
			  input: {
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				TableID: table_id,
				ExcludeChildren: false,
				ExcludePayload: excludePayload,
			  },
		  },
		  });

		  if(get_groups.Results) {
        this.setActiveTableGroups(JSON.parse(get_groups.Results));
		  }

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  @Action
  async getTableGroup({
  group_id,
  excludePayload = false,
  }) {
	  try {
		  const {
		  data: {
			get_group
		  },
		  // eslint-disable-next-line no-tabs
		  } = await scheduleApolloClient.query({
		  query: getGroup,
		  variables: {
			  input: {
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				GroupID: group_id,
				ExcludeChildren: false,
			  },
		  },
		  });

		  if(get_group.Results) {
			  let data = (JSON.parse(get_group.Results))
        	if(data){
				return data[Object.keys(data)[0]]
			}
		  }

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  @Action
  async updatePersonnelWorkspaceSched() {
	  try {
			let input = {
			  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  Username: accountModule.user.email.toLowerCase(),
			  CreateWorkspaceIfNotExists: true
			}
			const {
				data: {
					// @ts-ignore
					get_personnel_workspace_schedules: results
				},
				// eslint-disable-next-line no-tabs
				} = await scheduleApolloClient.query({
				query: getPersonnelWorkspaceSchedules,
				variables: {
					input
				},
			});
			this.resetPersonnelScheduleDetails()
			this.setPersonnelScheduleDetails(JSON.parse(results.Results))

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async getPersonnelWorkspaceSched({
  }) {
	this.resetPersonnelScheduleDetails()
	  try {
			let input = {
			  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  Username: accountModule.user.email.toLowerCase(),
			  CreateWorkspaceIfNotExists: true
			}
			const {
				data: {
					// @ts-ignore
					get_personnel_workspace_schedules: results
				},
				// eslint-disable-next-line no-tabs
				} = await scheduleApolloClient.query({
				query: getPersonnelWorkspaceSchedules,
				variables: {
					input
				},
			});

			this.setPersonnelScheduleDetails(JSON.parse(results.Results))

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async getShiftSchedule({
  }) {
	  try {
			let input = {
			  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  StartDateTime: new Date().toISOString().slice(0,-5).replace(/-/g,"-")
			}
			const {
				data: {
					// @ts-ignore
					get_personnel_schedule: results
				},
				// eslint-disable-next-line no-tabs
				} = await scheduleApolloClient.query({
				query: getPersonnelSchedule,
				variables: {
					input
				},
			});
			  return results.Results

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }









  @Action
  async getTagsForWell({
  node_id
  }) {
	  try {


			let input = {
			  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			}

			if(node_id !== null){
				// @ts-ignore
				input.NodeID = node_id
			}

		const {
			data: {
				// @ts-ignore
				get_tags: wellTags
			},
			// eslint-disable-next-line no-tabs
			}  = await scheduleApolloClient.query({
		  query: getWellTags,
		  variables: {
			  input
		  },
		  });
		//   console.log((wellTags.Results))
		//   if(wellTags.Results){
			  return wellTags.Results.length ? wellTags.Results : []
		//   }

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async postNewTableGroup({
  username,
  workspace_id,
  table_id,
  group_name,
  columns
  }) {
	let usage = "SCHEDULE";
	if(this.activePage === 'Groups') {
	  usage = "ASSETS";
	}

	  try {
		  const {
		  data: {
			post_new_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postNewGroup,
		  variables: {
			  input: {
				Username: username.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				TableID: table_id,
				WorkspaceID: workspace_id,
				Columns: columns,
				GroupName: group_name,
				Usage: usage
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }


  @Action
  async postNewTableGroupPage({
  username,
  workspace_id,
  table_id,
  group_name,
  columns,
  payload,
  metadata,
  }) {
	let usage = "SCHEDULE";
	if(this.activePage === 'Groups') {
	  usage = "ASSETS";
	}

	  try {
		  const {
		  data: {
			post_new_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: postNewGroup,
		  variables: {
			  input: {
				Username: username.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				TableID: table_id,
				WorkspaceID: workspace_id,
				Columns: columns,
				GroupName: group_name,
				Metadata: metadata,
				Usage: usage,
				Payload: payload,
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }



  @Action
  async postNewWorkspaceTable({
	username,
	workspace_id,
	table_title,
	}) {

		let usage = "SCHEDULE";
	if(this.activePage === 'Groups') {
	  usage = "ASSETS";
	}
		try {
			const {
			data: {
			  post_new_table: result,
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewTable,
			variables: {
				input: {
				Username: username.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				WorkspaceID: workspace_id,
				TableTitle: table_title,
				Usage: usage
				},
			},
			});
			this.setRecentlyPostedTableID(result.ID);
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
  }





// ******************************************
// ************* OLD SCHEDULE ***************
// ******************************************



  @Mutation
  resetScheduledRowComponents(): void {
    this.scheduledRowComponents = [];
  }


  @Mutation
  resetTotalScheduledRowComponents(): void {
    this.totalScheduledRowComponents = [];
  }

  @Mutation
  addScheduledRow(data): void {

    this.scheduledRowComponents = data;
  }

  @Mutation
  addTotalScheduledRow(data): void {

	this.totalScheduledRowComponents = data
  }



  @Action
  async listAreas(input: any = {useExisting: false}): Promise<void> {
	try {
		const {useExisting} = input
		if(this.areas && this.areas.length > 2){
			return;
		}
		const {
		  data: {
			  // @ts-ignore
			get_areas: {
			  Results: Areas
			}
		  },
		} = await scheduleApolloClient.query({
		  query: listAreasQuery,
		  variables: {
			  input: {
			  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  }
		  },
		  fetchPolicy: 'cache-first',
		});

		await userPreferenceDB.setItem('get_areas',Areas)

		this.setAreas(Areas);
	} catch (error: any) {
		console.log(error.message)
		let areas: any = await userPreferenceDB.getItem('get_areas')
		if(areas && areas.length){
			this.setAreas(areas);
		}
	}
  }





  @Action
  async getColumnMappingData({
  }): Promise<void> {

  const {
      data: {
		  // @ts-ignore
        get_column_mapping: columnMappingTemplateData,
      },
    } = await scheduleApolloClient.query({
      query: getColumnMapping,
      variables: {
		  input: {

		  }
      },
    });
	var results = JSON.parse(columnMappingTemplateData.Results)
	this.setColumnMapping(results)

  }







  @Action
  async getScheduleTemplates({
  }): Promise<void> {

  const {
      data: {
		  // @ts-ignore
        get_schedule_templates: scheduledTemplates,
      },
    } = await scheduleApolloClient.query({
      query: getScheduleTemplates,
      variables: {
		  input: {

		  }
      },
    });

	var results = JSON.parse(scheduledTemplates.Results);
	this.setScheduleTemplates(results);

  }







  @Action
  async getOperatorAssets(): Promise<any> {
	  try {
		const {
			data: {
				// @ts-ignore
			  get_operator_assets: GetOperatorAssetsOutput,
			},
		  } = await workflowApolloClient.query({
			query: getOperatorAssets,
			variables: {
			  input: {
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				AssetTypes: ["AREAS","PADS","WELLS","ROUTES"]
			  },
			},
		  });

		if(GetOperatorAssetsOutput) {
			this.setOperatorAssetsOutput({data: GetOperatorAssetsOutput})
		}
	  } catch (error) {

	  }
	  return null;
  }


  @Action
  async getShiftTemplates(): Promise<any> {
	  try {
		const {
			data: {
				// @ts-ignore
				get_shift_templates: GetShiftTemplatesQueryOutput,
			},
		  } = await scheduleApolloClient.query({
			query: getShiftTemplates,
			variables: {
			  input: {
				Operator: getConfigEnv('OPERATOR_LOWERCASED')
			  },
			},
		  });
		if(GetShiftTemplatesQueryOutput) {
			return JSON.parse(GetShiftTemplatesQueryOutput.Results);
		}
	  } catch (error) {

	  }
	  return null;
  }



  @Action
  async getGanttChartIDs(): Promise<void> {

  const {
      data: {
		  // @ts-ignore
        get_gantt_chart_ids: getGanttChartIDsResults,
      },
    } = await scheduleApolloClient.query({
      query: getGanttChartIDs,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });

	for (var x = 0; x < getGanttChartIDsResults.results.length; x++) {
		this.setGanttJob(getGanttChartIDsResults.results[x]);
	}

	// this.resetRoutes();
    // for (let x = 0; x < enabledRoutesResults.results.length; x++) {
    //   const parsed_results = JSON.parse(enabledRoutesResults.results[x]);
    //   this.addRoute(parsed_results);
    // }
  }




  @Action
  async getEnabledRoutes() {

   try {
	const {
		data: {
			// @ts-ignore
		  get_enabled_routes: enabledRoutesResults,
		},
	  } = await scheduleApolloClient.query({
		query: getEnabledRoutes,
		variables: {
		  input: {
			Operator: getConfigEnv('OPERATOR_LOWERCASED')
		  },
		},
		fetchPolicy: 'cache-first',
	  });
	  this.resetRoutes();
	  for (let x = 0; x < enabledRoutesResults.results.length; x++) {
		const parsed_results = JSON.parse(enabledRoutesResults.results[x]);
		this.addRoute(parsed_results);
	  }
	  await userPreferenceDB.setItem('get_enabled_routes',this.enabledRoutes)
   } catch (error: any) {
		const enabledRoutes: any = await userPreferenceDB.getItem('get_enabled_routes');
		console.log(enabledRoutes);
		if (enabledRoutes && enabledRoutes.length) {
		  this.setRoute(enabledRoutes);
		}
   }
  }





//   delete_group(input: {GanttChartID: "", GroupStructure: ""}) {
//     error
//     result
//     success
//   }

  @Action
  async deleteWellTestGroup({
  gantt_chart_id,
  group_structure
  }) {

	  try {
		  const {
		  data: {
			delete_group: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteGroup,
		  variables: {
			  input: {
				GanttChartID: gantt_chart_id,
				GroupStructure: group_structure
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }





  @Action
  async deleteRule({
  rule_id
  }) {

	  try {
		  const {
		  data: {
			delete_rule: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteRule,
		  variables: {
			  input: {
				  RuleID: rule_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }









//   @Action
//   async scootFlagSelected({
//   task_id,
//   gantt_chart_id,
//   cluster_id
//   }) {

// 	  try {
// 		  const {
// 		  data: {
// 			put_scoot: {
// 			  },
// 		  },
// 		  } = await scheduleApolloClient.mutate({
// 		  mutation: putScoot,
// 		  variables: {
// 			  input: {
// 				  TaskID: task_id,
// 				  GanttChartID: gantt_chart_id,
// 				  ClusterID: cluster_id
// 			  },
// 		  },
// 		  });

// 	  } catch (e) {
// 		  console.error(e);
// 		  // @ts-ignore
// 		  throw new Error(e);
// 	  }
//   }












  @Action
  async deleteRig({
  rig_id
  }) {

	  try {
		  const {
		  data: {
			delete_rig: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteRig,
		  variables: {
			  input: {
				  RigID: rig_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }




  @Action
  async deletePrePostTasq({
  id
  }) {

	  try {
		  const
		  data = await scheduleApolloClient.mutate({
		  mutation: deletePrePostTasq,
		  variables: {
			  input: {
				  id: id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }


  @Action
  async completePrePostTasqJob({
  tasq_id,
  tasq_type,
  prediction_id
  }) {

	  try {
		  const {
		  data: {
			complete_pre_post_tasq_job: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: completePrePostTasqJob,
		  variables: {
			  input: {
				  TasqID: tasq_id,
				  TasqType: tasq_type,
				  PredictionID: prediction_id
			  },
		  },
		  });

	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }
  }















@Action
  async getScheduleRulesAndComponents({
    from_date,
    to_date,
  }): Promise<void> {
    this.resetScheduleRules();
    this.resetScheduleComponents();
    const {
      data: {
		  // @ts-ignore
        get_schedule_rules: scheduleRules,
      },
    } = await scheduleApolloClient.query({
      query: getScheduleRulesResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          from_date,
          to_date,
        },
      },
    });
    for (let x = 0; x < scheduleRules.results.length; x++) {
      const parsed_results = JSON.parse(scheduleRules.results[x]);
      this.addScheduleRule(parsed_results);
    }

    this.setScheduleComponents(JSON.parse(scheduleRules.structured_components));
  }




  @Action
  async getScheduledShifts({
	start_date,
    end_date,
	table_id,
	schedule_type = "Gantt"
  }): Promise<void> {
    this.resetScheduleJobs();
    const {
      data: {
		  // @ts-ignore
        get_scheduled_jobs_v2: scheduleJobs,
      },
    } = await scheduleApolloClient.query({
      query: getScheduleJobsV2,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          EndDate: end_date,
          StartDate: start_date,
		  TableID: table_id,
		  ScheduleType: schedule_type
        },
      },
    });
    for (let x = 0; x < scheduleJobs.results.length; x++) {
      const parsed_results = JSON.parse(scheduleJobs.results[x]);
      this.addScheduledJob(parsed_results);
    }
	// for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
	// 	const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
	// 	this.addScheduledRig(parsed_results);
	// }

	this.resetScheduledRowComponents();


	for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
		const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
		this.addScheduledRow(parsed_results);
	}

  }





  @Action
  async getPersonnelScheduledShiftsGanttSubSelection({
	schedule_id,
	start_date,
	end_date
  }): Promise<void> {
    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
    // this.resetScheduleJobs();
	start_date = new Date(start_date)
	end_date = new Date(end_date)

	var parsed_components: any = []
	// for (var x = 0; x < this.totalScheduledRowComponents.length; x++) {
	// 	for (var y = 0; y < this.totalScheduledRowComponents[x].rows.length; y++) {
	// 		var new_components: any = []
	// 		for (var z = 0; z < this.totalScheduledRowComponents[x].rows[y].components.length; z++) {
	// 			if (start_date < new Date(this.totalScheduledRowComponents[x].rows[y].components[z].start_date) && end_date > new Date(this.totalScheduledRowComponents[x].rows[y].components[z].end_date)) {
	// 				new_components.push(this.totalScheduledRowComponents[x].rows[y].components[z])
	// 			}
	// 		}
	// 	}
	// 	var new_schedule = JSON.parse(JSON.stringify(this.totalScheduledRowComponents[x]))
	// 	new_schedule.rows[0].components = new_components
	// 	parsed_components.push({
	// 		...new_schedule
	// 	})

	// }
	this.resetScheduledRowComponents();
	// this.addScheduledRow(parsed_components)
	this.addScheduledRow(this.totalScheduledRowComponents)


  }




  @Action
  async getPersonnelScheduledShiftsGantt({
	schedule_id,
	start_date,
	end_date
  }): Promise<void> {
    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
    this.resetScheduleJobs();
	start_date = new Date(start_date)
	end_date = new Date(end_date)
	var start_date_string = start_date.getFullYear() + "-" + pad(start_date.getMonth() + 1, 2, '0') + "-" + pad(start_date.getDate(), 2, '0') + "T" + pad(start_date.getHours(), 2, '0') + ":" + pad(start_date.getMinutes(), 2, '0')+ ":" + pad(start_date.getSeconds(), 2, '0');
	var end_date_string = end_date.getFullYear() + "-" + pad(end_date.getMonth() + 1, 2, '0') + "-" + pad(end_date.getDate(), 2, '0') + "T" + pad(end_date.getHours(), 2, '0') + ":" + pad(end_date.getMinutes(), 2, '0')+ ":" + pad(end_date.getSeconds(), 2, '0');
	const {
      data: {
		  // @ts-ignore
        get_personnel_schedule_shifts_gantt: scheduleRows,
      },
    } = await scheduleApolloClient.query({
      query: getPersonnelShiftsGantt,
      variables: {
        input: {
			Operator: getConfigEnv('OPERATOR_LOWERCASED'),
			EndDate: end_date_string,
			StartDate: start_date_string,
          	ScheduleID: schedule_id
        },
      },
    });


	var parsed_results = JSON.parse(scheduleRows.Results)


	this.resetTotalComponents();
	this.setTotalComponents(parsed_results)

  }




  @Action
  async getScheduledJobsV2({
	start_date,
    end_date,
	table_id,
	schedule_type = "Gantt"
  }): Promise<void> {
    this.resetScheduleJobs();
    const {
      data: {
		  // @ts-ignore
        get_scheduled_jobs_v2: scheduleJobs,
      },
    } = await scheduleApolloClient.query({
      query: getScheduleJobsV2,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          EndDate: end_date,
          StartDate: start_date,
		  TableID: table_id,
		  ScheduleType: schedule_type
        },
      },
    });
    for (let x = 0; x < scheduleJobs.results.length; x++) {
      const parsed_results = JSON.parse(scheduleJobs.results[x]);
      this.addScheduledJob(parsed_results);
    }
	for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
		const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
		// this.addScheduledRig(parsed_results);
	}

	this.resetScheduledRowComponents();


	for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
		const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
		this.addScheduledRow(parsed_results);
	}



	// for (let x = 0; x < formatted_response.length; x++) {
	// 	const parsed_results = formatted_response[x];
	// 	this.addScheduledRow(parsed_results);
	// }

  }






  @Action
  async getScheduledJobs({
	start_date,
    end_date,
  }): Promise<void> {
    this.resetScheduleJobs();
    const {
      data: {
		  // @ts-ignore
        get_scheduled_jobs: scheduleJobs,
      },
    } = await scheduleApolloClient.query({
      query: getScheduledJobsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          EndDate: end_date,
          StartDate: start_date
        },
      },
    });
    for (let x = 0; x < scheduleJobs.results.length; x++) {
      const parsed_results = JSON.parse(scheduleJobs.results[x]);
      this.addScheduledJob(parsed_results);
    }
	for (let x = 0; x < scheduleJobs.schedule_components_array.length; x++) {
		const parsed_results = JSON.parse(scheduleJobs.schedule_components_array[x]);
		// this.addScheduledRig(parsed_results);
	}


  }


  @Action
  async getPrePostTasqTemplates(): Promise<void> {
	this.resetPrePostTasqTemplate();
    const {
      data: {
        get_pre_post_tasq_templates: prePostTasqTemplates,
      },
    } = await scheduleApolloClient.query({
      query: getPrePostTasqTemplateResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED')
        },
      },
    });

	const templatePrePostTasqs = (prePostTasqTemplates.results).map(
		(t: PrePostTasqTemplate) => mapPrePostTasqTemplate(t),
	  );
	this.resetPrePostTasqTemplate();
	for (var x = 0; x < templatePrePostTasqs.length; x++) {
		if (templatePrePostTasqs[x].tasqType == "PreTasq") {
			this.addPreTasqTemplate(templatePrePostTasqs[x]);
		} else {
			this.addPostTasqTemplate(templatePrePostTasqs[x]);
		}
	}
  }





  @Action
  async getComponents(): Promise<void> {
    const {
      data: {
		  // @ts-ignore
        get_components: getComponentsResults,
      },
    } = await scheduleApolloClient.query({
      query: getComponentsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }


  @Action
  async getVendors(): Promise<void> {
    const {
      data: {
		  // @ts-ignore
        get_vendors: getVendorsResults,
      },
    } = await scheduleApolloClient.query({
      query: getVendorsResponse,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }









  @Action
  async deleteScheduledJob({
  workflow_task_id,
  }) {
	  try {
		  const {
		  data: {
			delete_scheduled_job: {
			  },
		  },
		  } = await scheduleApolloClient.mutate({
		  mutation: deleteScheduledJob,
		  variables: {
			  input: {
				WorkflowTaskID: workflow_task_id,
				Operator: getConfigEnv('OPERATOR_LOWERCASED')
			  },
		  },
		  });
	  } catch (e) {
		  console.error(e);
		  // @ts-ignore
		  throw new Error(e);
	  }

  }








@Action
	async postNewUserScheduleRule({
	username,
	rule_conditions_dict,
	}) {
		try {
			const {
			data: {
				post_new_user_schedule_rule: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewUserScheduleRule,
			variables: {
				input: {
				Username: username.toLowerCase(),
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				RuleConditionsDict: rule_conditions_dict,
				},
			},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}

	@Action
	async deleteRow({
	row_id,
	}) {

		try {
			const data  = await scheduleApolloClient.mutate({
			mutation: deleteRow,
			variables: {
				input: {
					ID: row_id,
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}







	@Action
	async deleteCustomShiftBatch({
		deleteShifts
	}) {

		try {
			const deleteCustomShiftInput = {
				// WorkspaceID: workspace_id,
				// ScheduleID: schedule_id,
				DeleteBatch: JSON.stringify(deleteShifts)
				// RowID: RowID,
				// Date: date,
				// StartTime: startTime,
				// EndTime: endTime,
				// Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				// Username: username,
				// AssetType: assetType,
				// Asset: asset,
				// TemplateID: shiftTemplateID
			}



			const {
				data: {
					delete_custom_shift_batch: result,
				},
			} = await scheduleApolloClient.mutate({
				mutation: deleteCustomShiftBatch,
				variables: {
					input: deleteCustomShiftInput,
				},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}





	@Action
	async putCustomShiftBatch({
		putShifts
	}) {

		try {
			const putCustomShiftInput = {
				// WorkspaceID: workspace_id,
				// ScheduleID: schedule_id,
				PutBatch: JSON.stringify(putShifts)
				// RowID: RowID,
				// Date: date,
				// StartTime: startTime,
				// EndTime: endTime,
				// Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				// Username: username,
				// AssetType: assetType,
				// Asset: asset,
				// TemplateID: shiftTemplateID
			}



			const {
				data: {
					put_custom_shift_batch: result,
				},
			} = await scheduleApolloClient.mutate({
				mutation: putCustomShiftBatch,
				variables: {
					input: putCustomShiftInput,
				},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



	@Action
	async postCustomShiftBatch({
		newShifts
	}) {

		try {
			const postCustomShiftInput = {
				// WorkspaceID: workspace_id,
				// ScheduleID: schedule_id,
				NewShifts: JSON.stringify(newShifts)
				// RowID: RowID,
				// Date: date,
				// StartTime: startTime,
				// EndTime: endTime,
				// Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				// Username: username,
				// AssetType: assetType,
				// Asset: asset,
				// TemplateID: shiftTemplateID
			}



			const {
				data: {
					post_custom_shift_batch: result,
				},
			} = await scheduleApolloClient.mutate({
				mutation: postCustomShiftBatch,
				variables: {
					input: postCustomShiftInput,
				},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}







	@Action
	async postCustomShift({
		date,
		startTime,
		endTime,
		username,
		assetType,
		asset,
		recurType,
		recurStartDate,
      	recurEndDate,
		weekdays,
		onDays,
		offDays,
		workspace_id,
		schedule_id,
		RowID,
		shiftTemplateID = null
	}) {

		try {
			const postCustomShiftInput = recurType == ""
			? {
				WorkspaceID: workspace_id,
				ScheduleID: schedule_id,
				RowID: RowID,
				Date: date,
				StartTime: startTime,
				EndTime: endTime,
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				Username: username,
				AssetType: assetType,
				Asset: asset,
				TemplateID: shiftTemplateID
			}
			: {
				WorkspaceID: workspace_id,
				ScheduleID: schedule_id,
				RowID: RowID,
				Date: date,
				StartTime: startTime,
				EndTime: endTime,
				Operator: getConfigEnv('OPERATOR_LOWERCASED'),
				Username: username,
				Recurring: {
					Type: recurType,
					WeekdayPayload: {
						Weekdays: weekdays,
						StartDate: recurStartDate,
						EndDate: recurEndDate
					},
					OnOffPayload: {
						OnDays: onDays,
						OffDays: offDays,
						StartDate: recurStartDate,
						EndDate: recurEndDate
					}
				},
				AssetType: assetType,
				Asset: asset,
				TemplateID: shiftTemplateID
			}


			const {
				data: {
					post_custom_shift: result,
				},
			} = await scheduleApolloClient.mutate({
				mutation: postCustomShift,
				variables: {
					input: postCustomShiftInput,
				},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}



	@Action
	async postNewShiftTemplate({
		shiftName,
		startTime,
		endTime,
		shiftColor,
	}) {
		const d = new Date();
		var createdBy = d.toISOString();
		createdBy = createdBy.substring(0, createdBy.length - 5)

		try {
			const {
				data: {
					post_new_shift_template: result,
				},
			} = await scheduleApolloClient.mutate({
				mutation: postNewShiftTemplate,
				variables: {
					input: {
						TemplateName: shiftName,
						StartTime: startTime,
						EndTime: endTime,
						Operator: getConfigEnv('OPERATOR_LOWERCASED'),
						Color: shiftColor,
						CreatedBy: createdBy
					},
				},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}


@Action
	async postNewScheduledJob({
	job_name,
	job_operator,
	job_summary,
	job_type,
	node_id,
	procedure_id,
	role,
	selected_end_date,
	selected_start_date,
	rig_id,
	rig_name,
	username,
	pre_tasq_checklist,
	post_tasq_checklist
	}) {

		try {
			const {
			data: {
				post_scheduled_job: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewScheduledJob,
			variables: {
				input: {
					JobName: job_name,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					JobOperator: job_operator,
					JobSummary: job_summary,
					JobType: job_type,
					NodeID: node_id,
					ProcedureID: procedure_id,
					Role: role,
					RigID: rig_id,
					RigName: rig_name,
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					Username: username.toLowerCase(),
					PreTasqChecklist: pre_tasq_checklist,
					PostTasqChecklist: post_tasq_checklist
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}
















	@Action
	async updateShiftScheduleTemplate({
		shiftName,
		startTime,
		endTime,
		shiftColor,
		shiftTemplateID
	}) {
		const d = new Date();
		var createdBy = d.toISOString();
		createdBy = createdBy.substring(0, createdBy.length - 5)

		try {
			const {
				data: {
					update_shift_schedule_template: result,
				},
			} = await scheduleApolloClient.mutate({
				mutation: updateShiftScheduleTemplateGraphql,
				variables: {
					input: {
						ShiftTemplateID: shiftTemplateID,
						TemplateName: shiftName,
						StartTime: startTime,
						EndTime: endTime,
						Operator: getConfigEnv('OPERATOR_LOWERCASED'),
						Color: shiftColor
						// Name: shiftName,
						// StartTime: startTime,
						// EndTime: endTime,
						// Operator: getConfigEnv('OPERATOR_LOWERCASED'),
						// Color: shiftColor,
						// CreatedBy: createdBy
					},
				},
			});
		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}












	@Action
	async putScheduledJob({
	workflow_task_id,
	job_name,
	job_operator,
	job_summary,
	job_type,
	node_id,
	procedure_id,
	rig_id,
	rig_name,
	selected_end_date,
	selected_start_date,
	username,
	pre_tasq_checklist,
	post_tasq_checklist
	}) {

		try {
			const {
			data: {
				put_scheduled_job: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: putScheduledJob,
			variables: {
				input: {
					JobName: job_name,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					SelectedOperatorEmail: job_operator,
					JobSummary: job_summary,
					JobType: job_type,
					NodeID: node_id,
					ProcedureID: procedure_id,
					RigID: rig_id,
					RigName: rig_name,
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					Username: username.toLowerCase(),
					PreTasqChecklist: pre_tasq_checklist,
					PostTasqChecklist: post_tasq_checklist,
					WorkflowTaskID: workflow_task_id
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}






	@Action
	async updateScheduledJobDateRanges({
	prediction_id,
	selected_end_date,
	selected_start_date,
	rig_id,
	rig_name
	}) {
		try {
			const {
			data: {
				update_scheduled_job_date_ranges: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: updateScheduledJobDateRanges,
			variables: {
				input: {
					SelectedEndDate: selected_end_date,
					SelectedStartDate: selected_start_date,
					PredictionID: prediction_id,
					RigID: rig_id,
					RigName: rig_name
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}





	@Action
	async postNewPrePostTasqTemplate({
		TasqType,
		TasqTitle,
		TasqSummary
	}) {

		try {
			const {
			data: {
				post_new_pre_post_tasq_template: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewPrePostTasqTemplate,
			variables: {
				input: {
					TasqType: TasqType,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					TasqTitle: TasqTitle,
					TasqSummary: TasqSummary
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}




	@Action
	async postNewRig({
		RigName,
		RigID
	}) {

		try {
			const {
			data: {
				post_new_rig: {
				},
			},
			} = await scheduleApolloClient.mutate({
			mutation: postNewRig,
			variables: {
				input: {
					RigName: RigName,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					RigID: RigID
				},
			},
			});

		} catch (e) {
			console.error(e);
			// @ts-ignore
			throw new Error(e);
		}
	}




}










export default getModule(ScheduleModule);
